/* eslint-disable */
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'
import moment from 'moment';
import jspdf from 'jspdf'

function generateArray(table) {
  var out = [];
  var rows = table.querySelectorAll('tr');
  var ranges = [];
  for (var R = 0; R < rows.length; ++R) {
    var outRow = [];
    var row = rows[R];
    var columns = row.querySelectorAll('td');
    for (var C = 0; C < columns.length; ++C) {
      var cell = columns[C];
      var colspan = cell.getAttribute('colspan');
      var rowspan = cell.getAttribute('rowspan');
      var cellValue = cell.innerText;
      if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

      //Skip ranges
      ranges.forEach(function (range) {
        if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
          for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
        }
      });

      //Handle Row Span
      if (rowspan || colspan) {
        rowspan = rowspan || 1;
        colspan = colspan || 1;
        ranges.push({
          s: {
            r: R,
            c: outRow.length
          },
          e: {
            r: R + rowspan - 1,
            c: outRow.length + colspan - 1
          }
        });
      };

      //Handle Value
      outRow.push(cellValue !== "" ? cellValue : null);

      //Handle Colspan
      if (colspan)
        for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
    }
    out.push(outRow);
  }
  return [out, ranges];
};

function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts) {
  var ws = {};
  var range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  for (var R = 0; R != data.length; ++R) {
    for (var C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = {
        v: data[R][C]
      };
      if (cell.v == null) continue;
      var cell_ref = XLSX.utils.encode_cell({
        c: C,
        r: R
      });
      
      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = XLSX.SSF._table[14];
        // cell.v = datenum(cell.v);
        cell.v = moment(cell.v,"").format("YYYY/MM/DD HH:mm:ss");
        
      } else cell.t = 's';

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
  
  
  return ws;
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export function export_table_to_excel(id) {
  var theTable = document.getElementById(id);
  var oo = generateArray(theTable);
  var ranges = oo[1];

  /* original data */
  var data = oo[0];
  var ws_name = "SheetJS";

  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);

  /* add ranges to worksheet */
  // ws['!cols'] = ['apple', 'banan'];
  ws['!merges'] = ranges;

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  });

  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), "test.xlsx")
}

export function export_json_to_excel({
  multiHeader = [],
  siren, 
  type,
  client, 
  deb,
  end,
  mac,
  signature,
  header,
  data,
  filename,
  merges = [],
  autoWidth = true,
  bookType = 'xlsx'
} = {}) {
  /* original data */
  filename = filename || 'excel-list'
  data = [...data]
  data.unshift(header);

  for (let i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i])
  }

  var ws_name = "SheetJS";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);

  if (merges.length > 0) {
    if (!ws['!merges']) ws['!merges'] = [];
    merges.forEach(item => {
      ws['!merges'].push(XLSX.utils.decode_range(item))
    })
  }

  if (autoWidth) {
    /*设置worksheet每列的最大宽度*/
    const colWidth = data.map(row => row.map(val => {
      /*先判断是否为null/undefined*/
      if (val == null) {
        return {
          'wch': 10
        };
      }
      /*再判断是否为中文*/
      else if (val.toString().charCodeAt(0) > 255) {
        return {
          'wch': val.toString().length * 2
        };
      } else {
        return {
          'wch': val.toString().length
        };
      }
    }))
    /*以第一行为初始值*/
    let result = colWidth[0];
    for (let i = 1; i < colWidth.length; i++) {
      for (let j = 0; j < colWidth[i].length; j++) {
        if (result[j]['wch'] < colWidth[i][j]['wch']) {
          result[j]['wch'] = colWidth[i][j]['wch'];
        }
      }
    }
    ws['!cols'] = result;
  }

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var wbout = XLSX.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });


  // pour avoir un fichier zip 

    var b = new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
      })

    var file = new File([signature], "signature.txt", {type: "text/plain;charset=utf-8"});

    
      var doc = generatePDF( siren, type, client, deb, end, mac);
      
    
      



    var jszip = require("jszip")

    var zip = new jszip();
    zip.file( `${filename}.${bookType}`, b)
    zip.file( "signature.txt", file)
    zip.file("certificat.pdf", doc.output('blob'))



    zip.generateAsync({type:"blob"}).then(function(content) {
      
        // see FileSaver.js
        saveAs(content, `${filename}.zip`);
    });


}


 // Generate PDF 
 function generatePDF( siren,type,  client, deb, end, mac){
               
            
  const doc = new jspdf('p', 'pt', 'A4');

  // image 
   var imgI = new Image();
   imgI.src =require("@/assets/images/documentLogo/isidoors.png")
   doc.setFontSize(11);
   doc.addImage(imgI, "PNG", 200, 20,216,60,"isidoors");
   
   // couleur 
   doc.setTextColor("#DCDCDC")
   doc.text("_________________________________________________________________________",70,108)
   
   // couleur 
   doc.setTextColor("#000000")


   // texte 
   var strArr = doc.splitTextToSize("Attestation individuelle relative à l'utilisation du logiciel ou système de  caisse satisfaisant à des conditions d'inaltérabilité, de sécurisation, de conservation et d'archivage des données (CGI, art. 286, I-3° bis)  ", 450)
   doc.text(strArr, 70, 133);
   var strArr2 = doc.splitTextToSize("Pour le client : " +type +" "+ client  +"", 450)
    doc.text(strArr2,70,188)
   var strArr3 = doc.splitTextToSize(" Siren :  "+siren+"", 450)
   doc.text(strArr3, 70 ,203)
   var strArr4 = doc.splitTextToSize("Date d’extraction :  du "+ moment(deb,"").format("DD/MM/YYYY") +" au "+ moment(end,"").format("DD/MM/YYYY")+"" , 450)
   doc.text(strArr4, 70 ,218)
   var strArr5 = doc.splitTextToSize("Je soussigné, Sébastien Gontal, représentant légal de la société ISIDOORS SAS, éditeur de la solution Studio Connect installée sur l’appareil MAC  N° "+mac+" , propriété de la société " + client+"",450)
   doc.text(strArr5,70,248)
   var strArr6 = doc.splitTextToSize("Atteste, que les fonctionnalités de caisse installées sur l’appareil désigné  ci dessus, satisfont aux conditions d’inaltérabilité, de sécurisation,  de conservation et d’archivage des données en vue du contrôle de l’administration fiscale, prévues au 3° bis du I de l’article 286 du code général des impôts. ",450)
   doc.text(strArr6,70,303)
   var strArr7 = doc.splitTextToSize("Cette attestation a été produite sur la bases des informations officielles et publiques connues à ce jour, nous nous tiendrions informés des ultérieures modifications et ajusterons notre application par des mises à jour si nécessaire. ",450)
   doc.text(strArr7, 70, 368)
   var strArr8 = doc.splitTextToSize("Signature du représentant légal de l’éditeur du système de caisse", 450)
   doc.text(strArr8, 70, 423)
   
   // signature 

   var imgS = new Image();
   imgS.src =require("@/assets/images/documentLogo/signature.png")
   doc.addImage(imgS, "PNG", 400, 428,156,85,"signature");

   // text 

   var strArr9 = doc.splitTextToSize("Pour effectuer le contrôle de l’intégrité du fichier, veuillez l’extraire depuis l’interface du client.", 450)
   doc.text(strArr9, 70, 533)
   var strArr10 = doc.splitTextToSize("Puis charger le dans la page suivante : dev.studioconnect.fr/attestationcaisse ", 450)
   doc.text(strArr10,70, 558 )
   var strArr11 = doc.splitTextToSize(" \u2022     Si le fichier à été modifié, les clés de signature ne correspondront pas et s’affichera en ROUGE et ne divulguera pas la clé.", 450)
   doc.text(strArr11, 90, 588)
   var strArr12 = doc.splitTextToSize(" \u2022     Si le fichier est identique, les clés de signature correspondront et s’affichera en VERT en laissant apparaitre la clé.", 450)
   doc.text(strArr12,90, 625 )


   var strArr13 = doc.splitTextToSize("L’algorithme de Hash est calculé sur le SHA 256.",450)
   doc.text(strArr13,70, 660)
   var strArr14= doc.splitTextToSize("Il est rappelé que l’établissement d’une fausse attestation est un délit pénal passible de 3 ans d’emprisonnement et de 45 000 € d’amende (Code pénal, art. 441-1). L'usage d'une fausse attestation est passible des mêmes peines.",450)
   doc.text(strArr14, 70, 682)

   // bas de page 
   // couleur 
   doc.setTextColor("#DCDCDC")
   doc.text("_________________________________________________________________________",70,780)
   
   // couleur 
   doc.setTextColor("#000000")

   // 
   
   var str = doc.splitTextToSize("ISIDOORS SAS, 30 Chemin des falaises 30400 Villeneuve les Avignon, ",450)
   doc.text(str, 112, 800)
   var str2 =doc.splitTextToSize("capital de 50.000€, 804310142 RCS Nîmes, Numéro de TVA : FR51804310142",450)
   doc.text(str2, 100,815)

   return doc
   
}